.App {
  overflow-x: hidden;
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

body {
  font-family: "Roboto", serif;
    width:100%;
  height:100%;
  color:#222222;
}

@media (max-width: 768px) {
  h1 {
    
    font-weight: 900;
    font-size: 28px !important;
    padding: 0;
    margin: 0;
  }
  
  h2 {
    font-size: 16px;
    font-weight: 300;
    padding: 0;
    margin: 0;
  }
  
  h3 {
    font-size: 14px;
    font-weight: 300;
    padding: 0;
    margin: 0;
  }
  }
  
  @media (min-width: 768px) {

    .minimum-page-height{
      min-height: 80vh;
    }

    h1 {
      
      font-weight: 900;
      font-size: 28px;
      padding: 0;
      margin: 0;
    }
    
    h2 {
      font-size: 18px;
      font-weight: 300;
      padding: 0;
      margin: 0;
    }
    
    h3 {
      font-size: 16px;
      font-weight: 300;
      padding: 0;
      margin: 0;
    }
    }

.page-container{
  background-color: #fff !important;
  display: flex;
  justify-content: center;

}

.main-left-rail{
  background-color:#F8F9FA !important;

}


.no-scroll{
  overflow:hidden;

}

.module-container{
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 10px;
  max-width: 1200px;
  width:100%;
}

.legalPageContainer{
  padding:50px;
}
.active{
  background-color: #f0f4ff; /* Light subtle background */
  color: #333; /* Darker color for the active text */
  font-weight: 500; /* Slightly thicker font for the active item */
  border-left: 4px solid #007bff; /* Thin blue border on the left */
  padding-left: 16px; /* Adjust padding to account for the border */
}



@media (max-width: 768px) {


  .sidenav-banner-spacing{
    margin-top: 120px !important;
  }

  .onboarding-toast{
    display: flex;
    font-size:16px;
  }

  .onboarding-toast-icon-img{
    width:50px;
  }

  .app-tile-mobile-results{
    margin: 0 !important;
}

  .page-container{
    background-color: #fff !important;
    width:100%;
    height:100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    padding:2px;
    box-sizing: border-box;
  }
  
  .main-left-rail{
    background-color:#F8F9FA !important;
    height:80px;
    width:100%;
    min-height: 90px;
    position:fixed;
    z-index:999;
  }
  
  .main-right-canvas{
    height:100%;
    width: 100%;
    padding:3px;
    margin-top:100px;
  }

  .main-right-canvas-with-global-nav{
    height:100%;
    width: 100%;
    padding:3px;
    margin-top:150px !important;
  }

  
  .sidenav {
    position: fixed;
    top:0;
    z-index:9999;
    background-color: #ffffff;
    height: 100vh;
   margin-top: 60px;
  }





  .input-group{
    padding:10px 5px;
    width:100%;
  }
  

}
.wrapped-inputs{
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {

  .module-container{

    padding:20px;
    margin:10px;

  }

  .sidenav-banner-spacing{
    margin-top: 50px !important;
  }


  .main-right-canvas-with-global-nav{
    
    margin-top:50px !important;
  }

  .global-banner-ctas{
    position: absolute;
    width:100%;
    text-align: right;
    padding-right: 50px;
  }

  .global-banner{
    font-size: 18px;
    position:fixed;
    min-height: 50px;

}

  .onboarding-toast{
    display: flex;
    font-size:20px;
  }

  .onboarding-toast-icon-img{
    width:70px;
  }

  .page-container{
    width:100%;
    height:100%;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 60px;
    
  }
  
  .main-left-rail{
    height:100%;
    width: 280px !important;
    flex: 0 0 280px;
    position: fixed;
  }
  
  .main-right-canvas{
    height:100vh;
    flex-grow: 1;  /* Takes up remaining space */
  }

  .main-right-canvas-margin{

    margin-left: 240px;
  }


  
  .main-right-canvas {

    
    padding: 20px;
    height: 100vh; /* Full height of the viewport */
    overflow-y: auto; /* Enable scrolling for the route components */
    background-color: #ffffff;
    
   
  }



  .input-group{
    padding:20px;
  }
  
}

.module-header{
  flex: 0 0 100%;
  box-sizing: border-box;
  padding-bottom: 10px;
}
.module-title{
  font-weight: 700;
  font-size: 24px;
}

.module-subtitle{
  font-weight: 300;
  font-size: 16px;
  color:#222;
}



.module-container-horizontal{
  display: flex;
  flex-wrap: wrap;

}

.black-ghost-button{
  font-size: 14px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  padding:12px 30px;
  background-color: transparent
}

.black-ghost-button:hover{
 color:white;
  background-color: black;
  border: 1px solid white;
  
}



.module-content{
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  border-radius: 10px;
  padding:20px 0;
  height:0;
}

.input-lg {
  width:200px;
  height:10px;
  padding:10px;
  border: 1px solid lightgray;
  border-radius: 5px;
}


.input-label{
  font-weight: 700;
font-size: 12px;
}

.half-row-module{
  max-width: 350px;
}

.module-header-note{
  font-size: 12px;
  font-weight: 300;
}
.get-it-now-btn{
  padding:10px 20px;
  color:#4339F2;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #4FD1C5;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.tooltip-class{
  opacity: 1 !important;
}
.module-min-height{
  min-height: 300px;
}
.login-button-large{
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 96px;
  width:100%;
  max-width: 400px;
  border-radius: 10px;
  height: 44px;
 
  font-size: 18px;
  font-weight: 700;
  background-color: #fff;
  border:1px solid #8c8c8c !important;

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}


.login-button-medium{
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 96px;
  width:100%;
  max-width: 400px;
  border-radius: 10px;
  height: 44px;
 
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  border: 2px solid #d3d3d3;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}

.back-carrot{
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  padding: 0 20px;
  width:100%;
}

.stacked-module-container{
  width:100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;

}

.module-header-with-cta{
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.login-container-desktop{
  min-height: 600px;
  background-color: #f6f6fe;
  padding:30px;
}


.custom-modal {
  background-color: white;
  padding: 20px;
  width: 400px;
  border-radius: 8px;
  outline: none; /* Remove default focus outline */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional: shadow effect */
  position: relative;
  z-index: 1001; /* Ensure modal content is above overlay */
}

.custom-modal {
  background-color: white;
  padding: 20px;
  
  border-radius: 8px;
  outline: none; /* Remove default focus outline */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional: shadow effect */
  position: relative;
  z-index: 1001; /* Ensure modal content is above overlay */
}


/* Modal overlay styling */
.custom-overlay {
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay background */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center; /* Centers modal vertically and horizontally */
  z-index: 1000; /* Ensures modal appears on top of other elements */
}

.white-svg{
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(27deg) brightness(104%) contrast(113%);


}

.modal-title{
  font-size: 18x;
  font-weight: 700;
}

.text-link{
  font-weight: 700;
}


.profile-subscription-warning{
  font-size: 12px;
  text-align: center;
  background-color: orange;
  border-radius: 5px;
  padding: 5px;
  margin:5px;
}
.new-pill{
  font-size:12px;
  font-weight: 700;
  background-color: green;
  color:white;
  padding:5px;
  border-radius: 2px;

}
.modal-ctas{
  display: flex;
  width:100%;
  align-items: center;
 
}

.modal-close-cta{
  padding-left:5px;
  cursor: pointer;
  font-size: 14px;
}

.error{
  color:red;
  font-weight: 700;
  padding-bottom: 10px;
  width:100%;
  text-align: center;
}

.react-tooltip{
  z-index: 99999 !important;
}

@media (max-width: 768px) {


  .global-banner{
    font-size: 14px;
  }
  .app-owner-text{
      width:70%;
  }

  .appowner-desc{
      width:90%;
      height:250px;
  }

  .profile-content{
    width:100%;
  }

}
.onboarding-input-lg{

  font-family: 'Roboto', sans-serif;
  color: #333;
  font-size: 18px ;
    margin: 0 auto;
  padding: 10px;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: 1px solid gray;
  width: 90%;
  display: block;
  transition: all 0.3s;
  margin-top: 10px;

}

@media (min-width: 768px) {
  .app-owner-text{
      width:400px;
  }

  .appowner-desc{
      width:500px;
      height:200px;
  }
}

.app-owner-profile-cta{
  font-size: 20px;
  padding:10px;
  border-radius:5px;
  background-color:white;
  color:#4339F2;
  border: 1px solid #4339F2;
  margin: 30px 0;
  font-weight: 300;
  cursor: pointer;
  margin-right: 5px;
}


.app-owner-profile-ctas{
  display: flex;
  align-items: center;
  width:100%;
}

.enabled-text{
  font-size:14px;
  color:green;
}

.button-9-small-green {
  appearance: button;
  backface-visibility: hidden;
  background-color: green;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
  font-size: 14px;
  height: 33px;
  line-height: 1.15;
  margin: 10px 0;
  outline: none;
  overflow: hidden;
  padding: 5px 10px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s,box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 80px;
}

.module-header-cta{
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-9-xs-green {
  appearance: button;
  backface-visibility: hidden;
  background-color: green;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
  font-size: 12px;
  height: 33px;
  line-height: 1.15;
  margin: 5px;
  outline: none;
  overflow: hidden;
  padding: 5px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s,box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 50px;
}

/* CSS */
.button-9 {
  appearance: button;
  backface-visibility: hidden;
  background-color: #405cf5;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 12px 0 0;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s,box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 150px;
}

.button-9:disabled {
  cursor: default;
  background-color:gray;
}

.button-9:focus {
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}
.no-margin{
  margin: 0 !important;
}

.copy-to-clip-board-btn{
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:10px 8px;
  cursor: pointer;
  margin-right: 10px;
  background-color: #10ac84;
  color:white;
  border: none;
  border-radius: 8px;

}

.copy-cta{
  display: flex;
  align-items: center;
  
}

.copy-confirm{
  color: blue;
  font-size: 12px;
}

.top-level-content{
  display:flex;
  width:100%;
  flex-wrap: wrap;
  padding:20px;
  justify-content: center;
}

.top-level-data{
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.top-level-group{
  padding:0px 20px;
  width:150px;
  text-align: center;

}

.top-level-label{
  font-weight: 300;
  font-size: 14px;
}

.header-with-cta{
  max-width:500px !important;
}

.green{
  color:green;
}


.onboarding-toast-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}



.onboarding-toast-headline{
  font-weight: 700;
}

.onboarding-toast-text{
  display: flex;
  flex-direction: column;
}

.global-banner{
  width:100%;
  background-color: #0c0e31;
  height:50px;
  color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  
  position: fixed; /* Keep the banner fixed at the top */
  top: 0;
  z-index: 1000;
  min-height: 50px;
  
}

.green-ghost-button{
  font-size: 14px;
  border: 1px solid green;
  border-radius: 5px;
  cursor: pointer;
  padding:8px 11px;
  background-color: white;
  color:green;
  font-weight:300;
  margin:0 5px;
}

.green-ghost-button:hover{
  color:white;
   background-color: green;
   border: 1px solid white;
 
 }

.white-ghost-button:hover{
 color:#0c0e31;
  background-color: white;
  border: 1px solid white;

}


.blue-ghost-button{
  font-size: 14px;
  border: 1px solid #544cfb;
  border-radius: 5px;
  cursor: pointer;
  padding:10px 22px;
  width:130px;
  height:40px;
  background-color: #fff;
  color:#544cfb;
  font-weight:300;
  margin:0 5px;
}

.blue-ghost-button:hover{
 color:white;
  background-color: #544cfb;
  border: 1px solid white;

}


.white-ghost-button{
  font-size: 14px;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
  padding:10px 22px;
  background-color: #0c0e31;
  color:white;
  font-weight:700;
  margin:0 5px;
}

.white-ghost-button:hover{
 color:#0c0e31;
  background-color: white;
  border: 1px solid white;

}

