.footer{
    background-color: #1A1A2E;
    padding-bottom:40px;
    width:100%;
    color:#D1D1D1;
    display: flex;
    justify-content: center;
    font-size: 15px;
    padding-top: 10px;
    
}



.footer-container{
    max-width: 1000px;
    display: flex;
    justify-content: center;
    padding:40px;
    flex-wrap: wrap;

}

.footer-col{
    display: flex;
    flex-direction: column;
    width: 20%;
    min-width: 200px;
    padding-right: 25px;
    margin-bottom: 50px;
    gap:20px;
}

.no-decoration{
    color: #d2d1d1;
    text-decoration: none;
}

.no-decoration:hover{
    opacity: .6;
}

.footer-header{
    font-size: 24px;
    font-weight: 700;
}

@media (max-width: 768px) {
    .footer-container{
        margin: -1px;

    }
}