.league-teams{
    width:100%;
    display: flex;
    flex-wrap: wrap;
}

.league-team-view-header{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.league-team-view{
    margin:10px;
    height:300px;
    width:100%;
    border:1px solid #d6d6d6;
    border-radius: 8px;
    padding:10px;
}

.league-team-view-name{
    font-size:18px;
    font-weight: 700;
}

.team-line-item{
    width:100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
   
}


.team-member-row{
    display: flex;
    justify-content: space-between;

}



@media (min-width: 768px) {
    .league-team-view{
        max-width: 280px;
    }
    

}

.leagues-public-account{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
}